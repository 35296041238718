import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createBlock(_component_el_table, { data: _ctx.list }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "资金安排年度",
        prop: "year"
      }),
      _createVNode(_component_el_table_column, {
        label: "市级/省直",
        prop: "city_Name"
      }),
      _createVNode(_component_el_table_column, {
        label: "区县",
        prop: "country_Name"
      }),
      _createVNode(_component_el_table_column, {
        label: "文保单位",
        prop: "heritageUnit_Name"
      }),
      _createVNode(_component_el_table_column, {
        label: "项目编号",
        prop: "project_Code"
      }),
      _createVNode(_component_el_table_column, {
        label: "项目名称",
        prop: "project_Name"
      }),
      _createVNode(_component_el_table_column, {
        label: "已安排（万元）",
        prop: "project_Fund_Amount"
      }),
      _createVNode(_component_el_table_column, {
        label: "已支出",
        prop: "project_Fund_Amount_Used"
      }),
      _createVNode(_component_el_table_column, {
        label: "资金执行率",
        prop: "fundDoPercent"
      }),
      _createVNode(_component_el_table_column, {
        label: "资金执行状态",
        prop: "project_FundDo_Status"
      }),
      _createVNode(_component_el_table_column, {
        label: "执行进度说明",
        prop: "fundDoSlowReason"
      })
    ]),
    _: 1
  }, 8, ["data"]))
}