import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createBlock(_component_el_table, { data: _ctx.list }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "地区",
        prop: "areaName"
      }),
      _createVNode(_component_el_table_column, {
        label: "文保单位",
        prop: "heritageUnitName"
      }),
      _createVNode(_component_el_table_column, {
        label: "事项编码",
        prop: "applyCode",
        width: "100"
      }),
      _createVNode(_component_el_table_column, {
        label: "事项名称",
        prop: "thingName",
        width: "140",
        align: "center"
      }, {
        default: _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.things.find(p => p.code == item.applyCode)?.thingsName + (item.ApplyType ?
                        `(${_ctx.things.find(p => p.applyType == item.applyType)?.applyTypeName})` : '')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "申请文件标题",
        prop: "applyFileTitle"
      }, {
        default: _withCtx(({ row: item }) => [
          _createVNode(_component_el_button, {
            type: "text",
            onClick: ($event: any) => (_ctx.$router.push({ name: 'ReportAndReviewThingsDetail', params: { id: item.id } }))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.applyFileTitle), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "项目名称",
        prop: "projectName",
        align: "center"
      }),
      _createVNode(_component_el_table_column, {
        label: "申请单位",
        prop: "applyPerson",
        align: "center"
      }),
      _createVNode(_component_el_table_column, {
        label: "经办人",
        prop: "doPerson",
        align: "center"
      }),
      _createVNode(_component_el_table_column, {
        label: "状态",
        prop: "status",
        width: "100"
      }, {
        default: _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.thingApplyStatusText[item.status]), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}