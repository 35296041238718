import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { href: "javascript:void()" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createBlock(_component_el_table, { data: _ctx.list }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "编号",
        prop: "code"
      }),
      _createVNode(_component_el_table_column, {
        label: "地区",
        prop: "areaName"
      }),
      _createVNode(_component_el_table_column, {
        label: "名称",
        prop: "project_Name",
        width: "400"
      }, {
        default: _withCtx(({ row: item }) => [
          _createVNode(_component_router_link, {
            to: {
    name: `ReportAndReview${item.setupGroup == 1 ? 'Prov' : 'Nation'}SetupView`,
    params: { id: item.id },
  }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.project_Name), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "工程类型",
        prop: "projectType"
      }, {
        default: _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.isSafeProject == 1
      ? _ctx.dicLabel(item.safeProjectType, _ctx.scheduleTypeDic)
      : _ctx.dicLabel(item.projectType, _ctx.scheduleTypeDic)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "文保单位",
        prop: "heritageUnitName"
      }, {
        default: _withCtx(({ row: item }) => [
          _createVNode("a", _hoisted_1, _toDisplayString(item.heritageUnitName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "文保单位类型",
        prop: "heritageUnitClassName"
      }),
      _createVNode(_component_el_table_column, {
        label: "是否革命文物",
        prop: "heritageUnit_Name"
      }, {
        default: _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.isRevolution ? "是" : "否"), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "状态" }, {
        default: _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.setupProjectStatusText[item.status]), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "编制人",
        prop: "createUserName"
      })
    ]),
    _: 1
  }, 8, ["data"]))
}